<template>
    <div id="dc-sms">
        <div id="dc-header">
            <h3>SMS</h3>
            <!-- <b-button class="dc-button-primary" @click="smsCustomersList = customersList">Select all</b-button> -->
            <!-- <b-button class="dc-button-primary" @click="smsCustomersList = []">Remove all</b-button> -->
            <b-button class="dc-button-primary" @click="onClickSendSMS">Send SMS</b-button>
        </div>

        <hr />
        <v-client-table id="dc-sms-table" :data="customersList" :columns="columns" :options="options">
            <div class="dc-actions-container" slot="actions" slot-scope="{ row }">
                <b-form-checkbox size="lg" :checked="true" @change="onChangeCheckbox($event, row)"></b-form-checkbox>
            </div>
        </v-client-table>

        <!-- Modal - SMS Confirmation -->
        <b-modal id="dc-modal-sms-confirmation" ref="dc-modal-sms-confirmation" size="lg" title="Send SMS Confirmation" centered hide-footer>
            <!-- Message -->
            <div style="display: flex; align-items: center; justify-content: center">
                <b-form-textarea class="dc-input-message" v-model="message" placeholder="Message" rows="6" maxlength="160" required></b-form-textarea>
                <span style="margin-left: 40px">Remaining: {{ remainingCharSize }} chars</span>
            </div>

            <b-button class="mt-4 dc-button-primary" block @click="sendSMS">Send</b-button>

            <div style="margin-top: 50px">
                <div v-for="customer in smsCustomersList" :key="customer.id" class="dc-modal-list-container">
                    <span>{{ customer.id }}</span> <span>{{ customer.name }}</span> <span>{{ customer.phone }}</span>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import _ from "lodash";
import CustomerAPI from "./../customer/APICalls";
import APICalls from "./APICalls";

export default {
    name: "Products",
    data() {
        return {
            columns: ["id", "name", "phone", "quickbooksId", "actions"],
            customersList: [],
            smsCustomersList: [],
            options: {
                perPage: 200,
                orderBy: {
                    column: "name",
                    ascending: true,
                },
                templates: {},
            },
            remainingCharSize: 160,
            message: "",
        };
    },
    methods: {
        onChangeCheckbox(isChecked, customer) {
            if (isChecked) {
                this.addToList(customer);
            } else {
                this.removeFromList(customer);
            }
        },
        onClickSelectAll() {},
        onClickRemoveAll() {},
        onClickSendSMS() {
            this.sendSMSConfirmation();
        },
        sendSMSConfirmation() {
            this.$refs["dc-modal-sms-confirmation"].show();
        },
        addToList(customer) {
            this.smsCustomersList.push(customer);
        },
        removeFromList(customer) {
            let finalList = _.remove(this.smsCustomersList, (cust) => {
                return cust.id != customer.id;
            });
            this.smsCustomersList = finalList;
        },
        async getCustomers() {
            try {
                let customers = await CustomerAPI.getCustomers();
                this.customersList = customers;
                this.smsCustomersList = customers;
            } catch (errResponse) {
                console.log(errResponse);
                this.$router.push({ path: "/login" });
            }
        },
        // Send SMS
        async sendSMS() {
            console.log("Total customers:", this.smsCustomersList.length);

            // Empty message
            if (this.message.trim().length === 0) {
                this.$toasted.show("Error: No message found", { position: "bottom-center", className: "dc-toast-error", duration: 5000 });
                return;
            }

            // let totalSuccessMessage = 0;

            for (let index in this.smsCustomersList) {
                let customer = this.smsCustomersList[index];

                if (customer.phone == "" || customer.phone == null || customer.phone == undefined) continue;

                let phoneList = customer.phone.split(",");

                for (let index in phoneList) {
                    let phone = phoneList[index];
                    phone = phone.trim();
                    phone = phone.replaceAll(" ", "");
                    phone = phone.replaceAll("-", "");
                    // console.log(phone);

                    if (phone && phone.length == 10) {
                        try {
                            setTimeout(async () => {
                                let response = await APICalls.sendSMS(phone, this.message);
                                // totalSuccessMessage += 1;
                                console.log(response);
                            }, 1000);
                        } catch (errResponse) {
                            console.log(errResponse);
                        }
                    }
                }
            }

            // this.message = "";
            this.$toasted.show(`Completed`, { position: "bottom-center", className: "dc-toast-success", duration: 5000 });
            this.$refs["dc-modal-sms-confirmation"].hide();
        },
    },
    watch: {
        message() {
            this.remainingCharSize = 160 - this.message.length;
        },
    },
    mounted() {
        this.getCustomers();
    },
};
</script>

<style lang="scss">
#dc-sms {
    #dc-header {
        background: transparent;
        box-shadow: none;
        display: flex;
    }

    .dc-icon-edit {
        width: 20px;
        height: 20px;
        color: black;
        cursor: pointer;
    }

    .dc-icon-delete {
        width: 20px;
        height: 20px;
        margin-left: 20px;
        color: #800000;
        cursor: pointer;
    }

    .dc-button-primary {
        width: 180px;
    }

    #dc-sms-table {
        table {
            tbody {
                tr {
                    td:nth-child(3) {
                        max-width: 200px;
                    }
                }
            }
        }
    }
}

#dc-modal-sms-confirmation {
    .modal-body {
        // Message
        textarea {
            width: 300px !important;
            margin-bottom: 20px !important;
        }

        // List
        .dc-modal-list-container {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #007bc4;
            margin-bottom: 10px;
            padding-bottom: 10px;

            span:nth-child(1) {
                width: 60px;
                font-weight: bold;
            }

            span:nth-child(2) {
                width: 400px;
            }

            span:nth-child(3) {
                width: 300px;
            }
        }
    }
}
</style>
